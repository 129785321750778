module.exports = {
  search: 'Vyhľadať',
  title: 'Filter',
  hide: 'Skryť filter',
  show: 'Zobraziť filter',
  reset: 'Reset',
  resetFull: 'Reset filter',
  skillLevel: 'Úroveň',
  tags: 'Skill',
  tagsOther: 'Ostatné',
  cooperationForm: 'Forma spolupráce',
  others: 'Ostatné',
  newsletter: 'Odber nových ponúk',
  paymentType: {
    title: 'Spôsob vyplácania',
    type: {
      tpp: 'TPP',
      invoice: 'Faktúra',
    },
  },
  salaryFilterTitle: 'Minimálne: {{salaryValue}} / mesiac',
  hourlySalaryFilterTitle: 'Maximálne: {{salaryValue}} / hod',
  key: {
    jobCategories: 'Kategória',
    jobTypes: 'Forma',
    skillLevels: 'Seniorita',
    cities: 'Mesto',
    countries: 'Krajina',
    tags: 'Skill',
    paymentTypes: 'Typ',
    salary: 'Plat',
    others: 'Ostatné',
  },
  dateFilter: {
    all: 'Všetky',
    thisYear: 'Tento rok',
    lastYear: 'Minulý rok',
  },
}
